@import "../_vars"
.licenceSelect
  padding-left: 0
  width: calc(100%  + 20px)
  table-layout: fixed
  tr:nth-child(odd)
    background-color: white!important
  $spacing: 10px
  border-spacing: $spacing!important
  margin-left: -$spacing
  td
    padding: 20px!important
    cursor: pointer
    background-color: $tvgLight
    border-radius: $borderRadius
    @media all and (max-width: 700px)
      display: block
      margin-bottom: 10px
.licenceSelected
  //width: 50%
  border: 2px solid $tvg!important
.licenceNotSelected
  //width: 50%
  border: 2px solid white!important

.qualification
  background-color: $tvgLight
  padding: 20px
  margin-bottom: 10px
  border-radius: $borderRadius


.licenceQuery
